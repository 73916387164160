.cancel{
    text-decoration: none
}
.bg-pay{
    background-color: #eee;
    border-radius: 2px
}
.com-color{
    color: #8f37aa!important
}
.radio{
    cursor: pointer
}
label.radio input{
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none
}
label.radio div{
    padding: 7px 14px;
    border: 2px solid #8f37aa;
    display: inline-block;
    color: #8f37aa;
    border-radius: 3px;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 10px
}
label.radio input:checked+div{
    border-color: #8f37aa;
    background-color: #8f37aa;
    color: #fff
}
.fw-500{
    font-weight: 400
}
.lh-16{
    line-height: 16px
}

.image-text{
    display: flex;
    align-items: center;
}

.logo-text{
    display: flex;
    flex-direction: column;
}

.image-text .name {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 600;
    margin-left : 5px;
    margin-right : 20px;
}

.logo{
    width : 50px;
    height : 50px;
}

.article-content{
    border : 1px solid #000039
}

.fee-card-title{
    background-color : #000039;
    font-family: cursive;
}

.article-number{
    color : #000039
}

.stats{

    background: #f2f5f8 !important;

  color: #000 !important;
}
.articles{
    font-size:10px;
    color: #a1aab9;
}
.number1{
font-weight:500;
}
.followers{
  font-size:10px;
color: #a1aab9;

}
.number2{
font-weight:500;
}
.rating{
  font-size:10px;
color: #a1aab9;
}
.number3{
font-weight:500;
}