.hero {
  background-image: url("../../../../assets/site/img/HeroBg.png");
  padding: 20px 0;
}



.hero-left h1 {
  color: #fff;
  font-size: 55px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.hero-left p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 800px;
}

.hero-right {
  padding: 10px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
}



.hero-right h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hero-right p {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hero-left .text-strange{
  color: yellow
}





.amount input:focus {
  outline: none;
}

.hero-right a {
  background-color: #0d67b5;
  color: #fff;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  transition: all 0.3s ease;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.convert {
  background-color: #fff;
  color: #0d67b5;
  font-weight: 500;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  transition: all 0.3s ease;
  display: inline-block;
  text-align: center;
  border: 2px solid rgba(128, 128, 128, 0.518);
  margin-bottom: 10px;
  cursor: pointer;
}

.hero-right a:hover {
  opacity: 0.9;
}





.hero-right .input-group input{
  position:relative;
  height:90px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius:6px;
  padding-top: 30px;
  padding-left: 25px
}

.hero-right .input-group label{
  position:absolute;
  height: 24px;
  background: none;
  border-radius: 6px;
  line-height: 48px;
  font-size: 15px;
  color: gray;
  width:100%;
  font-weight:100;
  padding-left: 25px
}
.hero-right input:focus + .hero-right label{
  color: #1E88E5
}

select option {
  font-weight: 600;
}

@media (max-width: 955px) {
  

  .hero-left h1 {
    font-size: 32px !important;
    text-align: center;
  }

  .hero-left span {
    color: #ffe66b !important;
  }

  .hero-left p {
      display: none !important;
    }
  
    .hero-right{
      width: 100% !important;
    }  

    .hero-right .input-group input{
      position:relative;
      height:5px;
      width: 5px;
      margin-left: 1px;
      margin-right: 1px;
      border-radius:6px;
      padding-top: 30px;
      padding-left: 25px
    }
}

