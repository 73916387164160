.card-profile .radius-10 {
    border-radius: 10px !important;
}

.card-profile .border-info {
    border-left: 5px solid  #0dcaf0 !important;
}
.card-profile .border-danger {
    border-left: 5px solid  #fd3550 !important;
}
.card-profile .border-success {
    border-left: 5px solid  #15ca20 !important;
}
.card-profile .border-warning {
    border-left: 5px solid  #ffc107 !important;
}


.card-profile {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}

.card-profile .bg-gradient-scooter {
    background: #17ead9;
    background: -webkit-linear-gradient(45deg, #17ead9, #6078ea)!important;
    background: linear-gradient(45deg, #17ead9, #6078ea)!important;
}
.card-profile .widgets-icons-2 {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 27px;
    border-radius: 10px;
}
.card-profile .rounded-circle {
    border-radius: 50%!important;
}
.card-profile .text-white {
    color: #fff!important;
}
.card-profile .ms-auto {
    margin-left: auto!important;
}
.card-profile .bg-gradient-bloody {
    background: #f54ea2;
    background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676)!important;
    background: linear-gradient(45deg, #f54ea2, #ff7676)!important;
}

.card-profile .bg-gradient-ohhappiness {
    background: #00b09b;
    background: -webkit-linear-gradient(45deg, #00b09b, #96c93d)!important;
    background: linear-gradient(45deg,#00b09b, #96c93d)!important;
}

.card-profile .bg-gradient-blooker {
    background: #ffdf40;
    background: -webkit-linear-gradient(45deg,#ffdf40, #ff8359)!important;
    background: linear-gradient(45deg,#ffdf40, #ff8359)!important;
}

.card-profile .image-text{
    display: flex;
    align-items: center;
}

.card-profile .logo-text{
    display: flex;
    flex-direction: column;
}

.card-profile .image-text .name {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 600;
    margin-left : 5px;
    margin-right : 20px;
}

.card-profile .logo{
    width : 50px;
    height : 50px;
}

.card-profile .client-content{
    border : 1px solid #000039
}

.card-profile .client-title{
    background-color : #000039;
    font-family: cursive;
}

.card-profile .client-number{
    color : #000039
}

.card-profile .stats{

    background: #f2f5f8 !important;

  color: #000 !important;
}
.card-profile .articles{
    font-size:10px;
    color: #a1aab9;
}
.card-profile .number1{
    font-weight:500;
}
.card-profile .followers{
  font-size:10px;
    color: #a1aab9;
}
.card-profile .number2{
    font-weight:500;
}
.card-profile .rating{
  font-size:10px;
    color: #a1aab9;
}
.card-profile .number3{
    font-weight:500;
}