.review-heading h4 {
      color: #0d67b5;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-bottom: 10px;
}

/*.review-heading h3 {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
}*/

.footer {
      background-color: #0d67b5;
      padding: 60px 0;
}

.top-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
}

.copyright {
      padding-top: 50px;
      text-align: center;
      color: #fff;
}

.Logo-icon img {
      width: 150px;
}

.Logo-icon h1 {
      color: #fff;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
}

.top-footer h3 {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
}

.top-footer p {
      color: #fff;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
}

.top-footer a {
      color: #fff;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
}

.facebook {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
}

.address span {
      font-size: 30px;
      color: #fff;
}

.loadImage {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
}

@media (max-width: 768px) {
      .top-footer h3 {
            margin-top: 30px;
      }

      .Logo-icon {
            flex-basis: 100% !important;
      }

      .address {
            flex-basis: 100% !important;
      }

      .top-footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
      }

}