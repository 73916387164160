.profile-main .img-account-profile {
    height: 10rem;
}
.profile-main .rounded-circle {
    border-radius: 50% !important;
}
.profile-main .card {
    box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}
.profile-main .card .card-header {
    font-weight: 500;
}
.profile-main .card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}
.profile-main .card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}
.profile-main .form-control, .dataTable-input {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.profile-main .nav-borders .nav-link.active {
    color: #0061f2;
    border-bottom-color: #0061f2;
}

.profile-main .nav-borders .nav-link {
    color: #69707a;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}
.profile-main .fa-2x {
    font-size: 2em;
}

.profile-main .table-billing-history th, .table-billing-history td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
}
.profile-main .table > :not(caption) > * > *, .dataTable-table > :not(caption) > * > * {
    padding: 0.75rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.profile-main .border-start-primary {
    border-left-color: #0061f2 !important;
}
.profile-main .border-start-secondary {
    border-left-color: #6900c7 !important;
}
.profile-main .border-start-success {
    border-left-color: #00ac69 !important;
}
.profile-main .border-start-lg {
    border-left-width: 0.25rem !important;
}
.profile-main .h-100 {
    height: 100% !important;
}

.profile-main {
    margin-top:100px;
    background-color:#f2f6fc;   
    color:#69707a;
}

.profile-main .user-info-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: -1px;
    padding-top: 10px;
    padding-bottom: 30px;
    border: 1px solid #e1e7ec;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    overflow: hidden
}

.profile-main .user-info-wrapper .user-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background-position: center;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover
}

.profile-main .user-info-wrapper .user-cover .tooltip .tooltip-inner {
    width: 230px;
    max-width: 100%;
    padding: 10px 15px
}

.profile-main .user-info-wrapper .info-label {
    display: block;
    position: absolute;
    top: 18px;
    right: 18px;
    height: 26px;
    padding: 0 12px;
    border-radius: 13px;
    background-color: #fff;
    color: #606975;
    font-size: 12px;
    line-height: 26px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
    cursor: pointer
}

.profile-main .user-info-wrapper .info-label>i {
    display: inline-block;
    margin-right: 3px;
    font-size: 1.2em;
    vertical-align: middle
}

.profile-main .user-info-wrapper .user-info {
    display: table;
    position: relative;
    width: 100%;
    padding: 0 15px;
    z-index: 5
}

.profile-main .user-info-wrapper .user-info .user-avatar,
.user-info-wrapper .user-info .user-data {
    display: table-cell;
    vertical-align: top
}

.profile-main .user-info-wrapper .user-info .user-avatar {
    position: relative;
    width: 150px
}

.profile-main .user-info-wrapper .user-info .user-avatar>img {
    display: block;
    width: 100%;
    border: 5px solid #fff;
    border-radius: 50%
}

.profile-main .user-info-wrapper .user-info .user-avatar .edit-avatar {
    display: block;
    position: absolute;
    top: -2px;
    right: 2px;
    width: 36px;
    height: 36px;
    transition: opacity .3s;
    border-radius: 50%;
    background-color: #fff;
    color: #606975;
    line-height: 34px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 0;
    text-align: center;
    text-decoration: none
}

.profile-main .user-info-wrapper .user-info .user-avatar .edit-avatar::before {
    font-family: feather;
    font-size: 17px;
    content: '\e058'
}

.profile-main .user-info-wrapper .user-info .user-avatar:hover .edit-avatar {
    opacity: 1
}

.profile-main .user-info-wrapper .user-info .user-data {
    padding-top: 20px;
    padding-left: 12px
}

.profile-main .user-info-wrapper .user-info .user-data h4 {
    margin-bottom: 2px
}

.profile-main .user-info-wrapper .user-info .user-data span {
    display: block;
    color: #9da9b9;
    font-size: 13px
}

.profile-main .user-info-wrapper+.list-group .list-group-item:first-child {
    border-radius: 0
}

.profile-main .user-info-wrapper+.list-group .list-group-item:first-child {
    border-radius: 0;
}
.profile-main .list-group-item:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.profile-main .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.profile-main a.list-group-item {
    padding-top: .87rem;
    padding-bottom: .87rem;
}
.profile-main a.list-group-item, .list-group-item-action {
    transition: all .25s;
    color: #606975;
    font-weight: 500;
}
.profile-main .with-badge {
    position: relative;
    padding-right: 3.3rem;
}
.profile-main .list-group-item {
    border-color: #e1e7ec;
    background-color: #fff;
    text-decoration: none;
}
.profile-main .list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.125);
}

.profile-main .badge.badge-primary {
    background-color: #0da9ef;
}
.profile-main .with-badge .badge {
    position: absolute;
    top: 50%;
    right: 1.15rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.profile-main .list-group-item i {
    margin-top: -4px;
    margin-right: 8px;
    font-size: 1.1em;
}



.profile-main .comment {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding-left: 66px
}

.profile-main .comment .comment-author-ava {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    border-radius: 50%;
    overflow: hidden
}

.profile-main .comment .comment-author-ava>img {
    display: block;
    width: 100%
}

.profile-main .comment .comment-body {
    position: relative;
    padding: 24px;
    border: 1px solid #e1e7ec;
    border-radius: 7px;
    background-color: #fff
}

.profile-main .comment .comment-body::after,
.profile-main .comment .comment-body::before {
    position: absolute;
    top: 12px;
    right: 100%;
    width: 0;
    height: 0;
    border: solid transparent;
    content: '';
    pointer-events: none
}

.profile-main .comment .comment-body::after {
    border-width: 9px;
    border-color: transparent;
    border-right-color: #fff
}

.profile-main .comment .comment-body::before {
    margin-top: -1px;
    border-width: 10px;
    border-color: transparent;
    border-right-color: #e1e7ec
}

.profile-main .comment .comment-title {
    margin-bottom: 8px;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.profile-main .comment .comment-text {
    margin-bottom: 12px
}

.profile-main .comment .comment-footer {
    display: table;
    width: 100%
}

.profile-main .comment .comment-footer>.column {
    display: table-cell;
    vertical-align: middle
}

.profile-main .comment .comment-footer>.column:last-child {
    text-align: right
}

.profile-main .comment .comment-meta {
    color: #9da9b9;
    font-size: 13px
}

.profile-main .comment .reply-link {
    transition: color .3s;
    color: #606975;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .07em;
    text-transform: uppercase;
    text-decoration: none
}

.profile-main .active-side-link{
    background-color: #0061f2;
    color:#f2f6fc;
}

.profile-main .comment .reply-link>i {
    display: inline-block;
    margin-top: -3px;
    margin-right: 4px;
    vertical-align: middle
}

.comment .reply-link:hover {
    color: #0da9ef
}

.comment.comment-reply {
    margin-top: 30px;
    margin-bottom: 0
}

@media (max-width: 576px) {
    .comment {
        padding-left: 0
    }
    .comment .comment-author-ava {
        display: none
    }
    .comment .comment-body {
        padding: 15px
    }
    .comment .comment-body::before,
    .comment .comment-body::after {
        display: none
    }
}