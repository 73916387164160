.aboutsection {
      padding: 20px 0 0;
}

.about-left h4 {
      color: #0d67b5;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 800px;
      padding-bottom: 20px;
}

.about-left h2 {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 800px;
}

.about-left p {
      color: rgba(0, 0, 0, 0.50);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 800px;
      margin-bottom: 30px;
}

.about-left a {
      text-decoration: none;
      border-radius: 6px;
      background: #0D67B5;
      padding: 8px 40px;
      color: #fff;
      font-size: 18px;
      transition: all 0.3s ease;
      cursor: pointer;
}

.about-left button:hover {
      opacity: 0.9;
}

.about-right img {
      width: 600px;
      margin-top: 30px;
}

.flex-about {
      flex-wrap: wrap;
}