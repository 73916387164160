@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");


.value-item {
    margin-bottom: 50px;
}

/* .value-item img {
      background-color: #0d67b5;
      border-radius: 100%;
      padding: 9px;
      fill: #fff;
} */

.value-item h5 {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
}

.value-item p {
      color: rgba(0, 0, 0, 0.5);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
}

.value-item h5 {
      padding: 20px 0 5px;
}

.shadow,
.subscription-wrapper {
    box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1) !important
}

.icon-primary {
    color: #062caf
}

.icon-bg-circle {
    position: relative
}

.icon-lg {
    font-size: 30px
}


.icon-bg-primary::after {
    background: #062caf !important
}

.icon-bg-circle {
    border-radius: 50%;
    background: inherit;
    opacity: .1
}

.values p, .paragraph {
    font-weight: 400;
    color: #8b8e93;
    font-size: 15px;
    line-height: 1.6;
    font-family: "Open Sans", sans-serif
}

.icon-bg-yellow::after {
    background: #f6a622 !important
}

.icon-bg-purple::after {
    background: #7952f5
}

.icon-yellow {
    color: #f6a622
}

.icon-purple {
    color: #7952f5
}

.icon-cyan {
    color: #02d0a1
}

.icon-bg-cyan::after {
    background: #02d0a1
}

.icon-bg-red::after {
    background: #ff4949
}

.icon-red {
    color: #ff4949
}

.icon-bg-green::after {
    background: #66cc33
}

.icon-green {
    color: #66cc33
}

.icon-bg-orange::after {
    background: #ff7c17
}

.icon-orange {
    color: #ff7c17
}

.icon-bg-blue::after {
    background: #3682ff
}

.icon-blue {
    color: #3682ff
}