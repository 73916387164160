@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");


.contact-form input,
.contact-form textarea {
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 16px 32px;
  display: block;
  width: 100%;
  margin: 20px 0;
  font-size: 18px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
}

.contact-form button {
  border: none;
  border-radius: 6px;
  background: #0d67b5;
  padding: 12px 40px;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.contactus {
  padding: 70px 0 100px;
}

.contact-form h1 {
  color: #0d67b5;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 0 20px;
}

.contact-item h3 {
  color: #0d67b5;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contact-item p {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contact-item a {
  color: #000;
}

.get-in-touch {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-details, .contact-form {
  flex-basis: 50%;
}

.contact-item {
  margin-bottom: 50px;
}

.contact-item h3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}