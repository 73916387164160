.navbar {
      height: 100px;
      background-color: #fff;
      position: relative;
      z-index: 1000;
      width: 100%;
}

.well-nav{
  background: linear-gradient(20deg, rgb(222, 221, 223) 0%, rgb(244, 245, 203) 100%);
}

.well-nav .logo-nav {
      display: flex;
      justify-content: flex-start;
      align-items: start;
}

.well-nav .logo-nav p {
      margin-block-start: 0em;
      margin-block-end: 0em;
}

.well-nav .logo-nav img {
      width: 70px;
}

.nav-container {
      margin: 0 auto;
      padding: 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
}

.nav-container a {
      text-decoration: none;
      color: #000;
}

.nav-elements {
      position: relative;
}

.cross,
.menu-icon {
      display: none;
}

.nav-elements ul {
      display: flex;
      justify-content: arround;
      list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
      margin-right: 20px;
}

.nav-elements ul a {
      font-size: 16px;
      font-weight: 400;
      color: #000;
      text-decoration: none;
      font-weight: 600;
}

.nav-elements ul a.active {
      color: #0D67B5;
      font-weight: 500;
      position: relative;
}

.nav-elements ul a.active::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      /* height: 2px; */
      background-color: #0D67B5;
}

.nav-elements .getstarted {
      background-color: #0D67B5;
      color: #fff !important;
      border-radius: 6px;
      padding: 9px;
}

.nav-elements .getstarted.active {
      color: #0D67B5;
      font-weight: 500;
      position: relative;
}

.nav-elements .getstarted.active::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 0px;
      background-color: #0D67B5;
}

@media (max-width: 1400px) {
      .menu-icon {
            display: block;
            cursor: pointer;
            font-size: 30px;
      }

      .cross {
            display: block;
            position: absolute;
            top: 30px;
            right: 50px;
            cursor: pointer;
            font-size: 30px;
      }

      .nav-elements {
            position: absolute;
            right: -30px;
            top: 0px;
            background-color: #fff;
            width: 0;
            height: 100vh;
            transition: all 0.3s ease-in;
            overflow: hidden;
            padding-left: 30px;
      }

      .nav-elements.active {
            width: 270px;
      }

      .nav-elements ul {
            display: flex;
            flex-direction: column;
      }

      .nav-elements ul li {
            margin-right: unset;
            margin-top: 22px;
      }
}