.aboutImage img {
  width: 100%;
  margin: 80px 0 60px;
}

.mission-vision h1 {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mission-vision p {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mission-vision {
      margin-top: 60px;
}

.aboutus {
      padding-top: 80px;
}