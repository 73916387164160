.questions {
  padding: 20px 0;
}

.question-item {
  border-radius: 6px;
  background: #ffffff;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.top-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.top-item img {
      cursor: pointer;
      transition: all 0.3s ease;
}

.question-list {
  margin-top: 60px;
}

.top-item h3 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.question-item p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  transition: all 0.3s ease;
}

.answer {
      display: none;
}