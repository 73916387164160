.blogsection {
  padding: 0 20px;
  margin-bottom: 50px;
}

.post-item {
  border-radius: 6px;
  margin: 0px -40px 80px;
}

.posts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px !important;
  padding-bottom: 20px !important;
}

.post-image {
  height: 350px;
  overflow: hidden;
  border-radius: 6px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.post-image img {
  border-radius: 6px;
}

.post-content h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
}

.post-content p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.post-content a {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.read-more {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.view-all {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #0d67b5;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
