.team {
  padding: 20px 0 100px;
}

.member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 6px;
  flex-wrap: wrap;
  margin-top: 40px;
}

.member-image {
  border-radius: 6px;
  flex-basis: 40%;
  height: 400px;
  overflow: hidden;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-position: center;
}

.member-image img {
  border-radius: 6px;
}

.team-info {
  flex-basis: 50%;
}

.team-info p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.team-info h3 {
  color: #0d67b5;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px;
}

.team-info h4 {
  color: rgba(0, 0, 0, 0.38);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
}

.team-info h5 {
  font-size: 24px;
}
