
.brand_logo_container {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
}

.brand_logo {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: 2px solid white;
}



.form-icon{
    color: #fff;
    background-color: #fff;
    font-size: 75px;
    line-height: 92px;
    height: 70px;
    width: 70px;
    margin: 10px auto ;
    border-radius: 50%;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.form-icon .logo{
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
}

.form-card{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 5px 10px 50px #888888;
    background-color:#fff;
}