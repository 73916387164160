
.brand_logo_container {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
}

.brand_logo {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: 2px solid white;
}

.form-icon{
    color: #fff;
    background-color: #fff;
    font-size: 75px;
    line-height: 92px;
    height: 90px;
    width: 90px;
    margin: 10px auto ;
    border-radius: 50%;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.form-icon .logo{
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
}

.form-card{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 5px 10px 50px #888888;
    background-color:#fff;
}

.form-card h3 {
    font-size: 18px;
    text-align: center;
    color:#626cd6;
}

/* Google & Apple button styling */
.form-card .login_option {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.form-card .login_option .option {
    width: calc(100% / 2 - 12px);
}

.form-card .login_option .option a {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #F8F8FB;
    border: 1px solid #DADAF2;
    border-radius: 5px;
    margin: 34px 0 24px 0;
    text-decoration: none;
    color: #171645;
    font-weight: 500;
    transition: 0.2s ease;
}

.form-card .login_option .option a:hover {
    background: #ededf5;
    border-color: #626cd6;
}
.form-card .login_option .option a img {
    max-width: 25px;
}
.form-card p {
    text-align: center;
    font-weight: 500;
}
.form-card .separator {
    position: relative;
}
/* Login option separator styling */
.form-card .separator span {
    background: #fff;
    z-index: 1;
    padding: 0 10px;
    position: relative;
}
.form-card .separator::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    height: 1px;
    background: #C2C2C2;
    display: block;
}
