body{background-color: #eee}
.form-control:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;box-shadow: 0 0 0 0rem rgba(0,123,255,.25)}
.btn-secondary:focus{box-shadow: 0 0 0 0rem rgba(108,117,125,.5)}
.close:focus{box-shadow: 0 0 0 0rem rgba(108,117,125,.5)}.mt-200{margin-top:200px}

.hero-right h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .hero-right p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .amount {
    margin-top: 10px;
  }
  
  .amount .receiving {
    border-radius: 6px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 12px 15px;
    font-size: 18px;
    margin-top: 10px;
    color: #0d67b5;
  }
  
  .amount input:focus {
    outline: none;
  }
  
  .hero-right a {
    background-color: #0d67b5;
    color: #fff;
    border-radius: 6px;
    width: 100%;
    padding: 12px;
    transition: all 0.3s ease;
    display: inline-block;
    text-align: center;
    text-decoration: none;
  }
  
  .convert {
    background-color: #fff;
    color: #0d67b5;
    font-weight: 500;
    border-radius: 6px;
    width: 100%;
    padding: 12px;
    transition: all 0.3s ease;
    display: inline-block;
    text-align: center;
    border: 2px solid rgba(128, 128, 128, 0.518);
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .hero-right a:hover {
    opacity: 0.9;
  }
  
  .amount select {
    width: 100px;
    height: 47px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 8px 12px;
    outline: none;
  }
  
  select option {
    font-weight: 600;
  }  

  @media (max-width: 955px) {
    .hero-right,
    .hero-left {
      flex-basis: 100% !important;
    }
  }
  