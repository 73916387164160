.team-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
}

.team-image {
  flex-basis: 30%;
}

.team-text {
  flex-basis: 60%;
}

.teamsection {
  position: relative;
  background-color: #0d67b5;
  padding: 100px 0;
}

.top-layer {
  position: relative;
}

.team-layer {
  background-color: #0d67b5;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-top-right-radius: 350px;
  border-bottom-left-radius: 200px;
}

.team-title h4 {
  color: #df9894 !important;
}

.team-title h3 {
  color: #fff !important;
}

.team-image {
  height: 500px;
  width: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px 200px 6px 100px;
}

.team-text h3 {
  color: #df9894;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.team-text h4 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.team-text p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.team-text a {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.step-number{
  width: 60px;
  height: 60px;
  top: -30px;
  border-width: 4px !important; 
  background-color: #d5f248
}

@media (max-width: 768px) {
  .team-image {
    flex-basis: 100%;
    margin-bottom: 30px;
  }

  .team-text {
    flex-basis: 100%;
  }

  .team-layer {
    border-top-right-radius: 150px;
    border-bottom-left-radius: 150px;
  }
  
}