.review {
  background-color: #0d67b5;
  padding: 0 0 50px;
}

.reviews {
  margin-top: 70px;
}

.review-heading {
  text-align: center;
  padding-top: 50px;
}

.review h4 {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.review h3 {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px;
}

.review .card {
  width: 350px;
  max-height: 850px;
  border-radius: 6px;
  background: #fff;
}

.review .review-card{
  max-height: 100px;
  overflow-y: scroll;
}

.review .card-content {
  padding: 30px 18px;
}

.review .card-content p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.review .card-content h3 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.review .card-content h4 {
  color: rgba(0, 0, 0, 0.64);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-image {
  height: 300px;
  width: 200px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.review .card-image .quote {
  position: absolute;
  left: 20px;
  bottom: -30px;
  width: 50px;
  height: 50px;
}

@media (max-width: 740px) {
  .review .card {
    width: 100% !important;
  }

  .review .card-image {
    height: 380px !important;
    width: 100% !important;
  }
}