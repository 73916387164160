.blog {
  padding: 100px 0;
}

.search {
  text-align: center;
  margin: 50px 0;
}

.search input {
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  width: 100%;
  padding: 16px 32px;
  color: rgba(0, 0, 0, 0.553);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search input:focus {
  outline: none;
}

.blog-details {
      padding: 100px 0;
}

.blog-heading {
  margin-bottom: 50px;
}

.blog-heading p {
  color: rgba(0, 0, 0, 0.38);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog-heading h1 {
  color: #000;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 20px;
}

.blog-heading h3 {
  color: #0d67b5;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog-heading img {
      width: 100%;
      border-radius: 6px;
}

.categories {
      text-align: center;
}

.categories p {
  border-radius: 6px;
  background: #fff;
  padding: 11px;
  display: inline-block;
  margin-right: 10px;
}

/* css for blog content */

.text_content img {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.text_content figure,
.text_content blockquote {
  margin: 0;
}

.text_content blockquote {
  font-size: 18px;
  font-style: italic;
  border-left: 8px solid rgba(128, 128, 128, 0.459);
  padding: 10px 0px 10px 20px;
}

.subtitle {
  font-size: 35px;
  color: rgba(128, 128, 128, 0.624);
}

.text_content h1 {
  font-size: 50px;
}

.text_content h2 {
  font-size: 40px;
}

.text_content h3 {
  font-size: 35px;
}

.text_content h4 {
  font-size: 35px;
}

.text_content p {
  line-height: 36px;
  font-size: 20px;
}

.text_content li {
  font-size: 22px;
  line-height: 36px;
}

.text_content h1,
.text_content h2,
.text_content h3,
.text_content h4,
.text_content h5,
.text_content h6 {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .text_content .mainTitle {
    font-size: 2.5rem;
    line-height: 45px;
  }

  .text_content .subtitle {
    font-size: 2.5rem;
    line-height: 40px;
  }

  .leave_message {
    font-size: 2rem;
    padding-bottom: 40px;
  }

  .postDetails {
    padding: 30px 20px 50px;
  }
}
